import React from 'react'
import styled, { keyframes } from "styled-components"

export const cubemove = keyframes`
25% {
  transform: translateX(42px) rotate(-90deg) scale(0.5);
  -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
} 50% {
  transform: translateX(42px) translateY(42px) rotate(-179deg);
  -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
} 50.1% {
  transform: translateX(42px) translateY(42px) rotate(-180deg);
  -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
} 75% {
  transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
} 100% {
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
}
`

const SpinnerContainer = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
  position: relative;

& .cube1, & .cube2 {
  background-color: #9c9c9c;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: ${cubemove} 1.8s infinite ease-in-out;
}

& .cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
`

const Spinner: React.SFC = () => (
  <SpinnerContainer>
    <div className='cube1'/>
    <div className='cube2'/>
  </SpinnerContainer>
)

export default Spinner