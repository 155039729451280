import React, { useEffect } from 'react';
// import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { CINEMA_META } from '../model/model';
import { extractData, THEMES } from '../components/layout'
import { ThemeProvider } from 'styled-components';
import Spinner from '../components/spinner';

const TicketingPage: React.SFC<any> = ( query ) => {
  const { mainCinemaId } = extractData(query)
  const { creaCinemaId } = CINEMA_META[mainCinemaId]
  const creaUrl = `https://www.webtic.it/#/shopping?action=loadLocal&localId=${creaCinemaId}`
  useEffect(() => {
    window.location.replace(creaUrl);
  });
  return (
    <>
      {/* <Helmet>
         <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"/>
         <script type="text/javascript" src="https://secure.webtic.it/angwt/js/webapi/jquery.webtic.api.4.0.js"/>
      </Helmet> */}
      <ThemeProvider theme={THEMES[mainCinemaId]}>
        <div id="webticFrame" style={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <p>Caricamento biglietteria</p>
          <Spinner/>
        </div>
      </ThemeProvider>
    </>
  )
}

export default TicketingPage

export const query = graphql`
query BigliettiPageQuery {
  ...alldata
}
`
